
import i18n from '@/lang';
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { updatePwdByIssueApi } from '@/api/login';
import {getModifyPs} from '@/utils/storage_session'
@Component
export default class extends Vue {
    public isModifyPs:any=getModifyPs()
    // loading
    public loading=false
    // data
    public resetData={
        loginPs:'',
        newLoginPs:'',
        confirmpassword:'',
    }
    // rules
    public rules = {
        loginPs: {
            required: true,
            message: i18n.t('login.oldpassword'),
            trigger: 'blur',
         },
        newLoginPs: {
                required: true,
                trigger: 'blur',
                validator:this.getCheckPassWord
            },
        confirmpassword: {
                required: true,
                trigger: 'blur',
                validator:this.getConfirmPassWord
             },
    };
    // 退出登录
    public async logOut(){
        await UserModule.LogOut().catch((err:any) => err);
        this.$router.push(`/login`)
    }
    // 退出
    public async checkOut() {
        this.$confirm(
            i18n.t('login.isOut') as string,
            i18n.t('login.operaConfirmTips') as string,
        {
            confirmButtonText: i18n.t('login.confirm') as string,
            cancelButtonText: i18n.t('login.cancel') as string,
            type: 'warning',
        })
        .then(async () => {
            this.$router.replace(`/`);
        }).catch(()=> {
            this.$message.info(i18n.t('login.cancelOut') as string);
        });
    }
    // 密码检查
    public getCheckPassWord(rule: any, value: any, callback: any) {
        if(value.length>=8) {
            return callback()
        }
        return callback(new Error(i18n.t('login.validPwdNum') as string))
    }
    // 密码检查+是否一致
    public getConfirmPassWord(rule: any, value: any, callback: any) {
        if(value.length>=8) {
            if(this.resetData.newLoginPs!==this.resetData.confirmpassword) {
                return callback(new Error(i18n.t('login.twopsdNotConsistent') as string))
            } else {
                return callback()
            }
        }
        return callback(new Error(i18n.t('login.validPwdNum') as string))
    }
    // 提交
    public reset() {
        const el: any=this.$refs.resetForm;
        el.validate((vaild: any)=> {
            if(vaild) {
                const sendData: any= {
                    id:UserModule.staffId,
                    loginId:UserModule.loginId,
                    loginPs:this.resetData.loginPs,
                    newLoginPs:this.resetData.newLoginPs,
                }
                updatePwdByIssueApi(sendData).then(async (res: any)=> {
                    if(res.data&&res.data.code==='1003') {
                        this.$message.success(i18n.t('login.passwordResetSuccessful') as any);
                        await UserModule.LogOut().catch((err:any) => err);
                        this.$router.push(`/login`)
                    } else {
                        this.$message.error(i18n.t('login.passwordResetFailed') as any);
                    }
                }).catch(()=> {
                    this.$message.error(i18n.t('login.passwordResetFailed') as any);
                })
            }
        })
    }
}
