
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'loginBtnComp'
})
export default class LoginBtnComp extends Vue {
  @Prop({type: Boolean, default: false}) public loading!: boolean;
  @Prop({type: String, default: ''}) public type!: string;
  @Prop({type: Boolean, default: false}) public isDefault!: boolean;

  public handleRegister() {
    this.$emit('handle-btn-click')
  }
}
