
import loginMixin from '../mixin/loginMixin';
import LoginBtnComp from './LoginBtnComp.vue';
import LoginTitleComp from './LoginTitleComp.vue';

import { encode, decode } from 'js-base64';
import { Form as ElForm } from 'element-ui';
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';

import { UserModule } from '@/store/modules/user';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { ILogin } from '@/interface/login';
import {checkLoginIsModifyPsApi} from '@/api/login';
import {setModifyPs} from '@/utils/storage_session'
@Component({
  name: 'loginFormComp',
  components: {
    LoginBtnComp,
    LoginTitleComp,
  },
})
export default class extends Mixins(loginMixin)  {
  @Ref('loginForm') public loginRef!: ElForm;

  public redirect?: string;
  public otherQuery: Dictionary<string> = {};
  public loading = false;
  public forgetPwd = false; // 忘记密码
  public capsTooltip = false; // 大写是否锁定标记
  public hasInvitationCode = true;
  public loginFormData: ILogin = {
    username: '',
    password: '',
    rememberMe: false,
  };

  // 登录校验
  public loginRules = {
    invitationCode: [
      { required: true, message: this.$t('login.invitationCodeMsg'), trigger: 'blur' }
    ],
    password: [
      { required: true, validator: this.validatePassword, trigger: 'blur'}
    ],
    username: [
      { required: true, validator: this.validateInput(this.$t('login.validUser') + ''), trigger: 'blur'}
    ],
  };

  @Watch('$route', { immediate: true })
  public onRouteChange(route: Route) {
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  public mounted() {
    if (localStorage.getItem('remember') && localStorage.getItem('remember') === 'true') {
      this.loginFormData.rememberMe = true;
    }
  }

  // 大写是否被锁定
  public checkCapslock(e: KeyboardEvent) {
    const { key } = e;
    this.capsTooltip = key !== null && key !== undefined && key.length === 1 && (key >= 'A' && key <= 'Z');
  }

  // 根据输入账号获取账号对应记住的密码信息
  public handleLoginIdInput(value: string) { //  && localStorage.getItem('login-id') === value
    if (localStorage.getItem(value)) {
      const password = localStorage.getItem(value + 'login-ps') || '';
      this.loginFormData.password = decode(password);
    } else {
      this.loginFormData.password =  '';
    }
  }

  // 处理否记住密码
  public handleRememberPsCheck(value: boolean) {
    localStorage.setItem('remember', value + '');
  }

  // 登录
  public handleLogin() {
    this.loginRef.validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        // 保存记住密码在本地
        UserModule.Login(this.loginFormData).then(async() => {
          this.rememberPs();
          this.resetFormData();
          const {data}:any=await checkLoginIsModifyPsApi({fkUserId:UserModule.staffId})
          setModifyPs(data.data)
          if(data.data===true){
            this.$router.replace({ path:'/reset'});
          }else{
            const pathQuery = this.$route.query as Dictionary<string>;
            const query = this.getOtherQuery(pathQuery);
            this.$router.replace({ path: pathQuery.redirect || '/index', query });
          }
        }).catch((err:any) => {
          if (err && !err.hasInvitationCode) {
            this.hasInvitationCode = err.hasInvitationCode;
            this.$nextTick(() => {
              (this.$refs.invitationCode as any).focus();
            })
          }
        }).finally(() => {
          this.loading = false
        });
      } else {
        this.loading = false;
        return false;
      }
    });
  }

  // 修改密码
  public handleModifyPwd() {
    this.$emit('handle-modify-pwd');
  }

  // 记住密码
  public rememberPs() {
    if (this.loginFormData.rememberMe) {
      const loginPs = this.loginFormData.password;
      localStorage.setItem(this.loginFormData.username + 'login-ps', encode(loginPs));
      localStorage.setItem(this.loginFormData.username, 'loginId');
    } else {
      localStorage.removeItem(this.loginFormData.username + 'login-ps');
      localStorage.removeItem(this.loginFormData.username);
    }
  }

  public clearValidate() {
    this.loginRef.clearValidate()
  }

  public resetFormData() {
    this.loginRef.resetFields();
  }

}
